import firebase from "firebase/app";
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import IVS from "aws-sdk/clients/ivs";

var firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_KEY_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_KEY_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
  };
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);

  // utils
const db = firebase.firestore()
const analytics = firebase.analytics()
const auth = firebase.auth()
const functions = firebase.functions();
const provider = new firebase.auth.GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/userinfo.email');
provider.addScope('https://www.googleapis.com/auth/userinfo.profile')

//collection references
const usersCollection = db.collection('users')
const contactusCollection = db.collection('contact_us')
const blogCollection = db.collection("blog")
const blogCatCollection = db.collection("blog_categories")
const corpClientsCollection = db.collection("corporate_clients")
const corpCodesCollections = db.collection("corporate_codes")
const pricingCollection = db.collection("pricing")
const coachListCollection = db.collection('coach_list')
const calCollection = db.collection("livestream_calendar")
const livestreamRecording = db.collection("livestream_recording")

// const aws = require("aws-sdk");
// aws.config.update({
//   apiVersion: "2006-03-01",
//   accessKeyId: process.env.VUE_APP_AWS_ACCESSKEYID,
//   secretAccessKey: process.env.VUE_APP_AWS_SECRETKEY,
//   region: process.env.VUE_APP_AWS_REGION,
// });
// const ivs = new aws.IVS({ apiVersion: "2020-07-14" });

// export utils/refs
export {
  firebase,
  db,
  auth,
  analytics,
  functions,
  contactusCollection,
  blogCollection,
  blogCatCollection,
  corpCodesCollections,
  corpClientsCollection,
  usersCollection,
  pricingCollection,
  coachListCollection,
  calCollection,
  livestreamRecording,
  provider
}