<template>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <main class="main-body modal-content clearfix">
            <button id="closeTalk" type="button" class="close" data-dismiss="modal" aria-label="Close">
                <img src="../assets/v2/images/icon/close.svg" alt="">
            </button>
            <div class="left-side">
                <div class="d-flex flex-column justify-content-between h-100">
                    <div class="row">
                        <div class="col-xl-10 col-lg-8 m-auto">
                            <blockquote>“What you do today can improve all your tomorrows.”</blockquote>
                            <span class="bio">—Ralph Marston</span>
                        </div>
                    </div>
                    <img src="../assets/v2/images/assets/ils_18.svg" alt="" class="illustration mt-auto">
                </div>
            </div> <!-- /.left-side -->
            <div class="right-side">
                <h2 class="form-title">Contact Us</h2>
                <form v-on:submit.prevent="submit('calltoaction')" data-vv-scope="calltoaction">
                    <div class="messages"></div>
                    <div class="row controls">
                        <div class="col-12">
                            <div class="input-group-meta form-group mb-15">
                                <label>Your Name*</label>
                                <input type="text" placeholder="Your Name" v-model="callToActionForm.name" name="actionname"
                                    :class="{ 'border-red': errors.has('calltoaction.name') }" class="required"
                                    v-validate="'required'" required="required" data-error="Name is required.">
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group-meta form-group mb-35">
                                <label>Your Email*</label>
                                <input v-model="callToActionForm.email" name="actionemail"
                                    :class="{ 'border-red': errors.has('calltoaction.email') }" class="required email"
                                    placeholder="Your Email Address" type="email" v-validate="'required|email'"
                                    id="actionemail" required="required" data-error="Valid business email is required." />
                                <!-- <input type="email" placeholder="Email Address" name="email" required="required" data-error="Valid email is required."> -->
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group-meta form-group mb-25">
                                <textarea placeholder="Share your message here" v-model="callToActionForm.message"
                                    name="actionmessage" :class="{ 'border-red': errors.has('calltoaction.message') }"
                                    class="required" v-validate="'required'" required="required"
                                    data-error="Please,leave us a message."></textarea>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="col-12">
                            <button class="theme-btn-seven w-100">Send Message</button>
                        </div>
                        <div class="col-12 mt-15">
                            <span class="fs15">By completing this form, you allow us to contact you at the email address provided. You may unsubscribe at any time. Your information will never be shared or sold.</span>
                        </div>
                    </div>
                </form>
            </div> <!-- /.right-side -->
        </main> <!-- /.main-body -->
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

var _ = require('lodash')

export default {
    name: "schedulecall",
    data() {
        return {
            callToActionForm: {
                name: "",
                email: "",
                message: ""
            },
        }
    },
    methods: {
        ...mapActions(["contactUs"]),
        submit(scope) {
            var _this = this;
            this.$validator.validateAll(scope).then(function (res) {
                if (res) {
                    _this
                        .contactUs({
                            name: _this.callToActionForm.name,
                            email: _this.callToActionForm.email,
                            phone: "",
                            message: _this.callToActionForm.message,
                            page: "Home"
                        })
                        .then(() => {
                            document.getElementById('closeTalk').click();
                            _this.$emit("clicked", true)
                            _this.callToActionForm.name = "";
                            _this.callToActionForm.email = "";
                            _this.callToActionForm.message = "";
                            _this.$validator.reset();
                            _this.$fire({
                                title: "Success",
                                text: "Someone will be in contact with you shortly.",
                                type: "success",
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            _this.$fire({
                                title: "Error",
                                text: "Sorry, there seems to be a problem.",
                                type: "error",
                            });
                        });
                }
            });
        }
    }

}
</script>
<style>
.modal-contact-popup-one .main-body .left-side blockquote {
    background: #FDE3C5 !important;
    text-align: center;
    border-left: none;
    margin: 0 !important;
    font-weight: 500;
}

.swal2-container {
    z-index: 10002 !important;
}

.fs15 {
    font-size: 15px;
}

.mt-15 {
    margin-top: 15px;
}
</style>