<template>
  <div class="theme-main-menu sticky-menu theme-menu-five">
      <div class="d-flex align-items-center justify-content-center">
          <div class="logo pointer" v-on:click="scrollToTop">
              <router-link :to="{ name: 'home' }">
                  <img data-name="Ingomu-(@ingomu)" src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/Ingomu-(@ingomu).png" alt="">
              </router-link>
          </div>

          <nav id="mega-menu-holder" class="navbar navbar-expand-lg">
              <div class="nav-container">
                  <button class="navbar-toggler">
                      <span></span>
                  </button>
                  <div class="navbar-collapse collapse" id="navbarSupportedContent">
                      <div class="d-lg-flex justify-content-between align-items-center">
                          <ul class="navbar-nav main-side-nav font-gordita" id="one-page-nav">
                              <li class="nav-item">
                                  <router-link :to="{ name: 'home', hash: '#about' }" @click.native="scrollTo('about')"
                                      class="nav-link">
                                      ABOUT
                                  </router-link>
                              </li>
                              <li class="nav-item">
                                  <router-link :to="{ name: 'home', hash: '#features' }"
                                      @click.native="scrollTo('features')" class="nav-link">
                                      FEATURES
                                  </router-link>
                              </li>
                              <li class="nav-item">
                                  <router-link :to="{ name: 'home', hash: '#freetrial' }" @click.native="scrollTo('freetrial')"
                                      class="nav-link">
                                      7-DAY TRIAL
                                  </router-link>
                              </li>
                              <li class="nav-item">
                                  <router-link :to="{ name: 'business' }" class="nav-link" :class="{ 'text-white': isDark }">
                                      FOR BUSINESS
                                  </router-link>
                              </li>
                              <li class="nav-item">
                                  <router-link :to="{ name: 'blog' }" class="nav-link" :class="{ 'text-white': isDark }">
                                      BLOG
                                  </router-link>
                              </li>
                              <li class="nav-item">
                                  <router-link :to="{ name: 'home', hash: '#faq' }" @click.native="scrollTo('faq')"
                                      class="nav-link">
                                      FAQ
                                  </router-link>
                              </li>
                              <li class="nav-item">
                                  <a href="#" data-toggle="modal" data-target="#contactModal" class="nav-link">
                                      CONTACT
                                  </a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </nav>
          <div class="right-widget">
              <!-- <ul class="d-flex align-items-center">
                  <li> -->
                      <div class="dropdown download-btn style-two">
                          <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                              Download
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a class="dropdown-item d-flex align-items-center" target="_blank"
                                  href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu">
                                  <img src="../assets/v2/images/icon/Android_robot.svg" class="h22" alt="">
                                  <span>Android</span>
                              </a>
                              <a class="dropdown-item d-flex align-items-center" target="_blank"
                                  href="https://apps.apple.com/us/app/ingomu/id1621410470">
                                  <img src="../assets/v2/images/icon/apple-icon.svg" class="h22" alt="">
                                  <span>IOS</span>
                              </a>
                          </div>
                      </div>
                  <!-- </li>
              </ul> -->
          </div>
      </div>
  </div> <!-- /.theme-main-menu -->
</template>

<script>
const TIMEOUT = 1;

export default {
  name: 'SiteHeader',
  props: {
      pageSchema: {
          type: String,
          default: 'light'
      }
  },
  data() {
      return {
          schema: this.pageSchema
      }
  },
  computed: {
      isDark() {
          return (this.schema == 'light') ? false : true
      }
  },
  mounted() {
      if (this.$route.hash) {
          setTimeout(() => {
              if (window.location.hash === this.$route.hash) {
                  const el = document.getElementById(this.$route.hash.slice(1))
                  if (el) {
                      window.scrollTo(0, el.offsetTop + 200)
                  }
              }
          }, TIMEOUT)
      }
  },
  methods: {
      scrollTo(hashtag) {
          document.getElementById(hashtag).scrollIntoView({ behavior: 'smooth' })
      },
      scrollToTop() {
          window.scrollTo(0, 0);
      }
  }
}
</script>
<style scoped>
.pointer {
  cursor: pointer
}

.theme-menu-five .right-widget .download-btn {
    display: block !important;
}

.theme-menu-five .right-widget .download-btn.style-two button {
    line-height: 48px !important;
}

.h22 {
  height: 22px;
}

@media (min-width: 1371px) {
    .navbar-expand-lg .navbar-toggler {
        display: none !important;
    }
}

@media (max-width: 1370px) {
    .navbar-expand-lg .navbar-toggler {
        display: block !important;
    }
}

@media (min-width: 1371px) {
    .navbar-expand-lg .navbar-collapse {
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

@media (max-width: 1370px) {
    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: column;
        flex-direction: column !important;
    }
}
</style>
