import Vue from 'vue'
import Vuex from 'vuex'

import contact from "./contact.module"
import blog from "./blog.module"
import company from "./corporate.module"
import livestream from "./livestream.module"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    contact,
    blog,
    company,
    livestream
  }
})