<template>
    <div class="theme-main-menu sticky-menu theme-menu-five">
        <div class="d-flex align-items-center justify-content-center">
            <div class="logo pointer" v-on:click="scrollToTop">
                <router-link :to="{ name: 'home' }">
                    <img data-name="Ingomu-(@ingomu)" src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/Ingomu-(@ingomu).png" alt="">
                </router-link>
            </div>

            <nav id="mega-menu-holder" class="navbar navbar-expand-lg">
                <div class="nav-container">
                    <button class="navbar-toggler">
                        <span></span>
                    </button>
                    <div class="navbar-collapse collapse" id="navbarSupportedContent">
                        <div class="d-lg-flex justify-content-between align-items-center">
                            <ul class="navbar-nav main-side-nav font-gordita" id="one-page-nav">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'companysignup', hash: '#companysignup' }" @click.native="scrollTo('companysignup')"
                                        class="nav-link">
                                        HOME
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'companysignup', hash: isPaid ? '#pricing' : '#signup' }"
                                        @click.native="scrollTo(isPaid ? '#pricing' : '#signup')" class="nav-link">
                                        SIGNUP
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'companysignup', hash: '#faq' }" @click.native="scrollTo('faq')"
                                        class="nav-link">
                                        FAQ
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div> <!-- /.theme-main-menu -->
</template>

<script>
const TIMEOUT = 1;

export default {
    name: 'SiteHeader',
    props: {
        pageSchema: {
            type: String,
            default: 'light'
        },
        isPaid: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            schema: this.pageSchema
        }
    },
    computed: {
        isDark() {
            return (this.schema == 'light') ? false : true
        },
        paid() {
            return this.isPaid
        }
    },
    mounted() {
        if (this.$route.hash) {
            setTimeout(() => {
                if (window.location.hash === this.$route.hash) {
                    const el = document.getElementById(this.$route.hash.slice(1))
                    if (el) {
                        window.scrollTo(0, el.offsetTop + 200)
                    }
                }
            }, TIMEOUT)
        }
    },
    methods: {
        scrollTo(hashtag) {
            document.getElementById(hashtag).scrollIntoView({ behavior: 'smooth' })
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    }
}
</script>
<style scoped>
.pointer {
    cursor: pointer
}

.h22 {
    height: 22px;
}</style>
