import * as fb from '@/api/firebase.js';
import { data } from 'jquery';
import { vuexfireMutations, firestoreAction } from 'vuexfire'

const state = {
    nextLivestream: [],
    livestreamNow: [],
    livestreamTest: []
}

const getters = {
    nextLivestream: state => state.nextLivestream,
    livestreamNow: state => state.livestreamNow,
    livestreamTest: state => state.livestreamTest
}

const actions = {
    getNextLivestream: firestoreAction((context, payload) => {
        var myDate = new Date();
        myDate.setHours(myDate.getHours() - 5)
        var todayStamp = fb.firebase.firestore.Timestamp.fromDate(myDate);
        return context.bindFirestoreRef('nextLivestream',
            fb.calCollection
                .where("livestream_time", ">=", todayStamp)
                .where("online", "==", true)
                .where("isCanceled", "==", false)
                .where("isTest", "==", false)
                .orderBy("livestream_time", "asc")
                .limit(1)
            // .startAt(todayStamp)
        )
    }),
    liveUpateRecordingLiveConfigurations(context) {
        fb.livestreamRecording.where("isLive", "==", true).where("isTest", "==", false)
            .onSnapshot((querySnapshot) => {
                var live = []
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    live.push(data)
                })
                state.livestreamNow = live
            })
            // fb.livestreamRecording.where("isLive", "==", true)
            // .onSnapshot((querySnapshot) => {
            //     var live = []
            //     querySnapshot.forEach((doc) => {
            //         let data = doc.data()
            //         live.push(data)
            //     })
            //     state.livestreamNow = live
            // })
    },
    async setUserOnline(context, payload) {
        fb.coachListCollection
            .doc(payload.coachListId)
            .collection("sessions")
            .doc(payload.sessionId)
            .collection("session_users")
            .doc(payload.uid)
            .set({
                user: {
                    user_id: payload.uid,
                    firstname: "Website",
                    profileimage: "https://dktoyr513tjgs.cloudfront.net/image/blank.png"
                },
                online: true,
                startdatetime: fb.firebase.firestore.FieldValue.serverTimestamp()
            })
    },
    async setUserOffline(context, payload) {
        fb.coachListCollection
                .doc(payload.coachListId)
                .collection("sessions")
                .doc(payload.sessionId)
                .collection("session_users")
                .doc(payload.uid)
                .update({
                    online: false,
                })
    },
}

export default {
    actions,
    state,
    getters
}
