<template>
	<div id="home">
		<div class="main-page-wrapper font-gordita">
			<!-- ===================================================
					Loading Transition
				==================================================== -->
			<section>
				<div id="preloader">
					<div id="ctn-preloader" class="ctn-preloader">
						<div class="animation-preloader">
							<div class="text-center">
								<img class="spinner" src="../assets/image/ingomu-preloader.gif" alt="" />
							</div>
							<!-- <div class="spinner"></div> -->
							<div class="txt-loading">
								<span data-text-preloader="i" class="letters-loading">
									i
								</span>
								<span data-text-preloader="n" class="letters-loading">
									n
								</span>
								<span data-text-preloader="g" class="letters-loading-green">
									g
								</span>
								<span data-text-preloader="o" class="letters-loading-green">
									o
								</span>
								<span data-text-preloader="m" class="letters-loading">
									m
								</span>
								<span data-text-preloader="u" class="letters-loading">
									u
								</span>
							</div>
						</div>
					</div>
				</div>
			</section>


			<!-- 
				=============================================
					Theme Main Menu
				============================================== 
				-->
			<SiteHeader></SiteHeader>


			<!-- 
				=============================================
					Theme Hero Banner
				============================================== 
				-->
			<div class="hero-banner-fourteen lg-container pb-0">
				<div class="container">
					<div class="row">
						<div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
							<div class="">
								<h1 class="hero-heading fs-50 fw-600" data-aos="fade-right">Now <span
										style="color: #46c346;">YOU</span> can
									afford personal coaching</h1>
								<p class="hero-sub-heading fs-20" data-aos="fade-right" data-aos-delay="100">Unlock your potential with our mobile coaching app! Gain unlimited access to expert coaches through live virtual sessions, on-demand videos, and one-on-one messaging. Conquer stress and anxiety, and achieve personal growth, wellness, and career success. Start your transformative journey today!</p>
								<div class="d-lg-flex align-items-center justify-content-between pr-xl-5"
									data-aos="fade-right" data-aos-delay="200">
									<div class="info"></div>
									<div class="price">$29/m</div>
								</div>
							</div>
						</div>

						<div class="screen-holder col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 sm-mt-50">
							<div class="rainbow">
								<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/assets/Ingomu-iphone-app-(@ingomu-@personal-coaching).png"
									alt="Ingomu app showing on iPhone" class="shapes vr-image" data-aos="fade-up">
							</div>
						</div> <!-- /.screen-holder -->
					</div>
				</div>
			</div> <!-- /.hero-banner-fourteen -->

			<!-- 
				=============================================
					Fancy Feature Twenty Five
				============================================== 
				-->
			<div class="fancy-feature-twentyFive lg-container mt-100 md-mt-50 sm-mt-0" id="empower">
				<div class="container">
					<div class="block-style-twentyFive">
						<div class="row align-items-center text-center">
							<!-- <div class="col-xl-7 col-lg-6 col-md-10 m-auto" data-aos="fade-right" data-aos-duration="1200">
									<div class="screen-container">
										<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/hand_phone_logo_v1.png"
											alt="">
									</div> 
								</div> -->
							<div class="col-xl-12 col-lg-12" data-aos="fade-left" data-aos-duration="1200">
								<div class="text-wrapper pl-xl-5">
									<p class="fs-20">
										Ingomu simplifies the coaching experience by offering you a curated selection of dozens of coaches. This focused approach eliminates the overwhelm of searching through hundreds of coaches and ensures that you have access to a group of professionals who can holistically address a wide range of issues, including relationships, burnout, accountability, self-care, leadership, financial wellness, healthy lifestyles, and more.
										<br><br>
										Elevate your well-being, reduce stress, enhance physical health, and boost mental resilience through live, virtual group coaching sessions and on-demand videos—right at your fingertips in the Ingomu app.
									</p>

								</div> <!-- /.text-wrapper -->
							</div>
						</div>
					</div> <!-- /.block-style-twentyFive -->

				</div>
			</div> <!-- /.fancy-feature-twentyFive -->

			<!--
				=====================================================
					Fancy Feature Three
				=====================================================
				-->
			<div class="fancy-feature-three mt-150 md-mt-80" id="about">
				<div class="container">
					<div class="row">
						<div class="col-lg-6">
							<div class="title-style-one">
								<h6 class="font-rubik text-dark">ABOUT</h6>
								<h2 class="fs-50 fw-600">Ingomu has coaches to help with whatever is causing you stress.
								</h2>
							</div> <!-- /.title-style-one -->

							<div class="counter-info-classic">
								<div class="counter-box-one color-one">
									<h2 class="number font-rubik"><span class="timer" data-from="0" data-to="50"
											data-speed="1200" data-refresh-interval="5">0</span>+</h2>
									<p class="font-rubik">live sessions<br> per month</p>
								</div> <!-- /.counter-box-one -->
								<div class="counter-box-one color-two">
									<h2 class="number font-rubik fs30">Dozens</h2>
									<p class="font-rubik">of coaches</p>
								</div> <!-- /.counter-box-one -->
								<div class="counter-box-one color-three">
									<h2 class="number font-rubik fs-35">Hundreds</h2>
									<p class="font-rubik">of on-demand sessions</p>
								</div> <!-- /.counter-box-one -->
								<div class="bubble-one"></div>
								<div class="bubble-two"></div>
								<div class="bubble-three"></div>
								<div class="bubble-four"></div>
								<div class="bubble-five"></div>
								<div class="bubble-six"></div>
								<div class="bubble-seven"></div>
								<img src="../assets/v2/images/shape/8.svg" alt="" class="cs-screen dot-shape">
							</div> <!-- /.counter-info-classic -->
						</div>

						<div class="col-lg-6">
							<div class="clients-feedback-classic md-mt-80">
								<div class="feedback-wrapper p40" data-aos="fade-up" data-aos-duration="1200"
									data-aos-anchor-placement="top-bottom">
									<img data-name="Ingomu-icon-(@Ingomu)" src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/Ingomu-icon-(@Ingomu).png"
										alt="" class="media-meta">
									<p class="text-dark fs-20">Ingomu users self-report an average increase in
										well-being of 65% after an Ingomu coaching session.</p>
									<!-- <h6 class="name">Institute of Coaching</h6> -->
									<!-- <span class="font-rubik disg-info">Institute of Coaching</span> -->
								</div> <!-- /.feedback-wrapper -->
								<div class="feedback-wrapper p40" data-aos="fade-up" data-aos-duration="1200">
									<img data-name="Ingomu-icon-(@Ingomu)" src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/Ingomu-icon-(@Ingomu).png"
										alt="" class="media-meta">
									<p class="text-dark fs-20">Ingomu coaching can reduce stress through improved sleep,
										elevated communication, and
										mindfulness and relaxation practices.</p>
									<!-- <h6 class="name">Rashed Ka.</h6> -->
									<!-- <span class="font-rubik disg-info">Human Capital Institute</span> -->
								</div> <!-- /.feedback-wrapper -->
								<div class="feedback-wrapper p40" data-aos="fade-up" data-aos-duration="1200">
									<img data-name="Ingomu-icon-(@Ingomu)" src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/Ingomu-icon-(@Ingomu).png"
										alt="" class="media-meta">
									<p class="text-dark fs-20">Ingomu members report increased self-awareness and
										improved coping strategies.</p>
									<!-- <h6 class="name">Zubayer Hasan</h6> -->
									<!-- <span class="font-rubik disg-info">International Coaching Federation</span> -->
								</div> <!-- /.feedback-wrapper -->
							</div> <!-- /.clients-feedback-classic -->
						</div>
					</div>
				</div>
			</div> <!-- /.fancy-feature-three -->

			<!--
				=====================================================
					Fancy Text block Eight
				=====================================================
				-->
			<div class="fancy-text-block-eight mt-100">
				<div class="container">
					<div class="title-style-two text-center md-mb-10">
						<div class="row">
							<div class="col-xl-8 col-lg-9 col-md-10 m-auto mt-100" id="features">
								<h2 class="fs-50 fw-600">
									<span>Features</span>
								</h2>
							</div>
						</div>
					</div>

					<div class="block-style-six md-pb-70">
						<div class="row">
							<div class="col-lg-5" data-aos="fade-right" data-aos-duration="1200">
								<div class="text-details pt-35 md-pb-50 md-pt-30">
									<h3 class="title font-gilroy-bold fs-30 pb-15">Interactive coaching</h3>
									<ul class="checkmark">
										<li class="text-dark">Message your coaches live in-session</li>
										<li class="text-dark">Send one-on-one messages between sessions</li>
										<li class="text-dark">Celebrate milestones achieved</li>
									</ul>
									<h3 class="title font-gilroy-bold pt-40 fs-30 pb-15">A wide variety of coaching
										communities
									</h3>
									<ul>
										<li class="text-dark"><b>CAREER:</b> leadership, decrease burnout, EQ,
											accountability, mindset,
											and more.</li>
										<li class="text-dark"><b>WELLNESS:</b> self-care, self-esteem, eating well,
											healthy lifestyles, and
											more.</li>
										<li class="text-dark"><b>LIFE:</b> parenting, debt-relief, dating, divorce,
											relationships, motherhood,
											and more.</li>
										<li class="text-dark"><b>SOUL:</b> mindfulness, meditation, stress reduction,
											and more.</li>
									</ul>
								</div> <!-- /.text-details -->
							</div>

							<div class="col-lg-7 col-md-9 m-auto" data-aos="fade-left" data-aos-duration="1200">
								<div class="illustration-holder illustration-one">
									<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/assets/Ingomu-app-features-(@ingomu-@coaching-app-features).png"
										alt="" class="ml-auto">
								</div>
							</div>
						</div>
					</div> <!-- /.block-style-six -->
				</div>
			</div> <!-- /.fancy-text-block-eight -->

			<!--
				=====================================================
					Fancy Short Banner Ten
				=====================================================
				-->
			<div class="fancy-short-banner-ten mt-130 md-mt-20 pb-0" id="freetrial">
				<div class="container">
					<div class="row">
						<div class="col-xl-7 col-lg-8 m-auto" data-aos="fade-up" data-aos-duration="1200">
							<div class="text-center">
								<img class="mx-auto d-block mb-40" data-name="Ingomu-(@ingomu)" src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/Ingomu-(@ingomu).png" alt="">
								<h2 class="font-gordita fs-50 fw-600">Start your 7-day free trial today!</h2>
								<p class="fs-20">Transform your life with unlimited access to experienced life,
									wellness, work, and soul coaches. Enjoy live virtual group coaching and watch
									recorded sessions at your convenience. Start with a 7-day free trial, then continue
									for $29/month, with the freedom to cancel anytime. Start your transformative journey
									today!</p>
							</div>
						</div>
					</div>
					<!-- <div class="text-center pb-40 md-pb-10" data-aos="fade-up" data-aos-duration="1200"
							data-aos-delay="150">
							<a href="#" data-toggle="modal" data-target="#contactModal" class="theme-btn-eleven">LET'S TALK</a>
						</div> -->

					<div class="d-flex justify-content-center pb-40 md-pb-10" data-aos="fade-up"
						data-aos-duration="1200" data-aos-delay="150">
						<div class="col-lg-3 col-sm-5">
							<div class="dropdown download-btn style-two w100">
								<button class="dropdown-toggle w100" type="button" id="dropdownMenuButton"
									data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									Download
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
									<a class="dropdown-item d-flex align-items-center" target="_blank"
										href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu">
										<img src="../assets/v2/images/icon/Android_robot.svg" class="h22" alt="">
										<span>Android</span>
									</a>
									<a class="dropdown-item d-flex align-items-center" target="_blank"
										href="https://apps.apple.com/us/app/ingomu/id1621410470">
										<img src="../assets/v2/images/icon/apple-icon.svg" class="h22" alt="">
										<span>IOS</span>
									</a>
								</div>
							</div>
						</div>


					</div>


				</div> <!-- /.container -->
				<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/210.svg" alt=""
					class="shapes shape-one">
				<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/211.svg" alt=""
					class="shapes shape-two">
			</div> <!-- /.fancy-short-banner-ten -->

			<!--
				=====================================================
					Fancy Short Banner Four
				=====================================================
				-->
			<div class="fancy-short-banner-four mt-100 mb-100" id="newsletter">
				<div class="container">
					<div class="bg-wrapper">
						<div class="row align-items-center">
							<div class="col-lg-6">
								<div class="title">
									<h6 class="text-white">ACTIONABLE TIPS FOR A BETTER LIFE</h6>
									<h2>Weekly coaching tips in your inbox</h2>
								</div> <!-- /.title-style-one -->
							</div>
							<div class="col-lg-6">
								<div class="form-wrapper">
									<form type="email" v-on:submit.prevent="submit('newsletter')" data-vv-scope="newsletter">
										<input class="form-control border-0 required email" v-model="newsletterForm.email" name="actionemail"
                                    :class="{ 'border-red': errors.has('newsletter.email') }"
                                    placeholder="Your Email Address" type="email" v-validate="'required|email'"
                                    id="actionemail" required="required" data-error="A valid email is required." />
										<button type="submit">Subscribe</button>
									</form>
									<p class="font-rubik text-white">
										By subscribing to our newsletter, you allow us to contact you at the email
										address
										provided. You may unsubscribe at any time. Your information will never be shared
										or
										sold.
									</p>
								</div> <!-- /.form-wrapper -->
							</div>
						</div>
					</div> <!-- /.bg-wrapper -->
				</div> <!-- /.container -->
			</div> <!-- /.fancy-short-banner-four -->

			<!--
				=====================================================
					Client Feedback Slider Seven
				=====================================================
				-->
			<div class="client-feedback-slider-seven md-mt-100 mt-100 md-mt-100 sm-mt-0 sm-pt-0" id="feedback">
				<div class="inner-container">
					<div class="container">
						<div class="row">
							<div class="col-lg-8 m-auto">
								<img src="../assets/v2/images/icon/143.svg" alt="" class="m-auto">
								<div class="title-style-eleven text-center mt-30">
									<h2 class="fs-50 fw-600"><span>What Ingomu users say.</span></h2>
								</div> <!-- /.title-style-eleven -->
							</div>
						</div>

						<div class="row">
							<div class="col-xl-8 col-lg-10 m-auto">
								<div class="clientSliderFive mt-80 md-mt-50">
									<div class="item">
										<p class="text-dark fs-20">I've been going through a tough time at work and it's
											been spilling over into my home life. Started using the app a while back and
											I've found so much support in the communities. They've really helped me come
											up with strategies to tackle the challenges, both at work and at home.
											They’ve boosted my confidence and helped me improve my relationships with my
											colleagues and family. I'm thankful for the impact this app has had on my
											life and career."</p>
										<div class="name text-dark fs-20">Aiden</div>
									</div>
									<div class="item">
										<p class="text-dark fs-20">The Ingomu app is amazing. As a nurse, I seldom have
											time to think about myself. I have learned a lot about setting boundaries
											and protecting my time, as well as building a more effective team at work.
											Using the app, I also have been able to put more focus on self-care.</p>
										<div class="name text-dark fs-20">MJ</div>
									</div>
									<div class="item">
										<p class="text-dark fs-20">What I love about Ingomu is the coaching that is
											available 24/7 on the app. I have worked every shift and every schedule, and
											this is the first time I have felt like quality support was at my
											fingertips, no matter what time of the day it was.</p>
										<div class="name text-dark fs-20">Lisa F.</div>
									</div>
									<div class="item">
										<p class="text-dark fs-20">I can't believe how energized I still feel after
											being part of Coach Sullivan's coaching session! Learning about turning
											mediocre teams into high performing ones was an absolute game-changer. The
											way she coached wasn't only packed with valuable insights, but the whole
											experience was like a burst of positivity and enthusiasm. </p>
										<div class="name text-dark fs-20">Ellie S.</div>
									</div>
									<div class="item">
										<p class="text-dark fs-20">I want to express my thanks for the app update. The
											new layout of the livestream has enhanced my connection with the coach,
											making it feel like the coach is speaking directly to me. Love it! Thx</p>
										<div class="name text-dark fs-20">Sutton</div>
									</div>
									<div class="item">
										<p class="text-dark fs-20">I love this app. It's so easy to participate in
											amazing remote coaching sessions, and I love to listen to coaching sessions
											on demand during my commute to and from work. Highly recommended.</p>
										<div class="name text-dark fs-20">ACM</div>
									</div>
								</div> <!-- /.clientSliderFive -->
								<ul class="d-flex justify-content-center slider-arrow mt-40">
									<li class="prev_f slick-arrow" style=""><i class="flaticon-right-arrow"></i></li>
									<li class="next_f slick-arrow" style=""><i class="flaticon-right-arrow"></i></li>
								</ul>
								<!-- <img src="../assets/v2/images/shape/156.svg" alt="" class="shapes shape-one">
									<img src="../assets/v2/images/shape/157.svg" alt="" class="shapes shape-two">
									<img src="../assets/v2/images/shape/158.svg" alt="" class="shapes shape-three"> -->
							</div>
						</div>
					</div>
				</div> <!-- /.inner-container -->
				<img src="../assets/v2/images/shape/bg7_v1.svg" alt="" class="bg-image d-none d-sm-block">
			</div> <!-- /.client-feedback-slider-six -->

			<!--
				=====================================================
					Faq Classic
				=====================================================
				-->
			<a href="#faq">&nbsp;</a>
			<div class="faq-classic with-bg pt-0 pb-0 bg-white md-mt-100 mt-100 sm-mt-0" id="faq" ref="faq">
				<div class="container">
					<div class="title-style-four text-center mb-100 md-mb-70">
						<div class="row">
							<div class="col-lg-7 m-auto">
								<h6 class="text-dark">FAQ’s</h6>
								<h2 class="fs-50 fw-600">
									<span>Questions & Answers</span>
								</h2>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-lg-6">
							<!-- ================== FAQ Panel ================ -->
							<div id="accordion">
								<div class="card bg-lblue" v-for="(item, index) in faqGeneral" :key="index">
									<div class="card-header" :id="item.heading">
										<h5 class="mb-0">
											<button class="btn btn-link">
												{{ item.question }}
											</button>
										</h5>
									</div>
									<div :id="item.controls" class="collapse">
										<div class="card-body">
											<p class="text-dark fs-20" v-html="item.answer"></p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-6">
							<!-- ================== FAQ Panel ================ -->
							<div id="accordiontwo">
								<div class="card bg-lblue" v-for="(item, index) in faqGeneral1" :key="index">
									<div class="card-header" :id="item.heading">
										<h5 class="mb-0">
											<button class="btn btn-link">
												{{ item.question }}
											</button>
										</h5>
									</div>
									<div :id="item.controls" class="collapse">
										<div class="card-body">
											<p class="text-dark fs-20" v-html="item.answer"></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> <!-- /.faq-classic -->

			<!--
				=====================================================
					Footer Style Seven
				=====================================================
				-->
			<SiteFooter></SiteFooter>

			<!-- Modal Contact Form One -->
			<div class="modal fade modal-contact-popup-one" id="contactModal" tabindex="-1" role="dialog"
				aria-labelledby="contactModalTitle" aria-hidden="true">
				<ContactUs @clicked="closeModal"></ContactUs>
			</div>

			<!-- Scroll Top Button -->
			<button class="scroll-top">
				<i class="fa fa-angle-up" aria-hidden="true"></i>
			</button>
		</div>
	</div>
</template>

<script>
import postscribe from "postscribe";
import SiteHeader from "@/components/SiteHeader";
import SiteFooter from "@/components/SiteFooter";
import ContactUs from "@/components/ContactUs";
import { mapActions, mapGetters } from "vuex";

const TIMEOUT = 1000;

export default {
	name: "home",
	components: {
		SiteHeader,
		SiteFooter,
		ContactUs
	},
	data() {
		return {
			newsletterForm: {
                email: ""
            },
			testimonialsList: [
				{
					img: "",
					name: "Joel F.",
					title: "Joined a much needed session on the app today. I am dealing with conflict at work due to the very different values and opinions on my team. It’s making work more than a bit difficult. During Carolyn’s session I gained great insights and effective strategies to overcome these challenges. Thanks and see you in the next session."
				},
				{
					img: "",
					name: "Lisa F.",
					title: "What I love about Ingomu is the coaching that is available 24/7 on the app.  I have worked every shift and every schedule, and this is the first time I have felt like quality support was at my fingertips no matter what time of the day it was."
				},
				{
					img: "",
					name: "Brock L.",
					title: "Ingomu has grounded me and given me new tools that are helping me function better, in life. I like having access to so many coaches at any given time, on a variety of topics. Ingomu has helped me and my family."
				},
				{
					img: "",
					name: "Greg B.",
					title: "Ingomu is at the frontline of creating a culture of caring in my organization. The transformative power of coaching is turning out excellent staff and associates."
				},
				{
					img: "",
					name: "MJ",
					title: "The Ingomu app is amazing. As a nurse, I seldom have time to think about myself. I have learned a lot about setting boundaries and protecting my time, as well as building a more effective team at work.  Using the app, I also have been able to put more focus on self-care."
				},
				{
					img: "",
					name: "Anastasia S.",
					title: "Where else can you get affordable access to unlimited coaching when you need it? I've been able to control my anxiety by joining live sessions. I'm less stressed and more focused! Thanks."
				},
				{
					img: "",
					name: "C. J.",
					title: "I love being able to join various coaching communities and engage with the coach in each one. The flexibility of joining live or watching on-demand is perfect."
				},
				{
					img: "",
					name: "Brad B.",
					title: "In partnership with Ingomu, we will achieve our goals for a thriving culture that promotes well-being."
				},
				{
					img: "",
					name: "Darren M.",
					title: "I’m up for a promotion and my manager suggested I check out the Ingomu app in preparation. I'm amazed by the impressive selection of coaches and the high-quality content and the impact it's already having on my personal and professional growth."
				}
			],
			faqCategories: [
				{
					id: "v-pills-general-tab",
					href: "#v-pills-general",
					controls: "v-pills-general",
					title: "General",
					class: "nav-link active",
				},
			],
			faqGeneral: [
				{
					question: "Who will be my coaches?",
					answer:
						"You have unlimited access to all life, work, wellness, and soul coaches on the app. Coaches are vetted, and where possible certified in their area of coaching.",
					id: "headingOne",
					target: "#collapseOne",
					controls: "collapseOne",
					show: "show",
				},
				{
					question: "How do I work with the coaches?",
					answer:
						"Via the Ingomu app you have access to all coaches. You can join scheduled live virtual group sessions, and in case you missed a session, or want to see it again, you can always watch any session on demand. You can also send one-on-one messages to your coaches between sessions.",
					id: "headingTwo",
					target: "#collapseTwo",
					controls: "collapseTwo",
					show: "",
				},
				{
					question: "How can I access Ingomu?",
					answer:
						'Ingomu is accessible via the Ingomu app for iOS and Android. You can download it from <a href="https://apps.apple.com/us/app/ingomu/id1621410470" target="_blank">Apple App Store</a> and <a href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu" target="_blank">Google Play</a>.',
					id: "headingThree",
					target: "#collapseThree",
					controls: "collapseThree",
					show: "",
				},
				{
					question: "What is included in my subscription?",
					answer:
						"Your subscription gives you access to all coaches and their live group coaching sessions, as well as hundreds of recorded sessions, accessible on-demand and on your time. Your subscription also gives you the opportunity to engage with coaches via discussions between sessions, and one-on-one messaging. Get the help you need, when you need it.",
					id: "headingFour",
					target: "#collapseFour",
					controls: "collapseFour",
					show: "",
				}
			],
			faqGeneral1: [
				{
					question: "How are subscriptions priced?",
					answer:
						"Subscriptions are $29 US per month after a 7-day full access free trial.  Once subscribed, upgrade and save with a semi-annual subscription at $165. Save even more with an annual subscription at $290 US. Imagine unlimited access to all our coaches for the cost of one coaching session. Contact us for volume pricing for your company, organization, institution or school.",
					id: "headingFive",
					target: "#collapseFive",
					controls: "collapseFive",
					show: "",
				},
				{
					question: "Can I try the app before signing up?",
					answer:
						'We invite you to sign up for a 7-day full access free trial. You can download the app for <a href="https://apps.apple.com/us/app/ingomu/id1621410470" target="_blank">iOS</a> or <a href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu" target="_blank">Android</a>.',
					id: "headingSix",
					target: "#collapseSix",
					controls: "collapseSix",
					show: "",
				},
				{
					question: "How do I cancel my subscription?",
					answer:
						"You can cancel your subscription at any time by going to the App or Play store on your device. Follow instructions to cancel your plan. Make sure you cancel your plan no less than 24 hours prior to your renewal date to ensure you do not get billed.",
					id: "headingSeven",
					target: "#collapseSeven",
					controls: "collapseSeven",
					show: "",
				},
				{
					question: "How do I delete my account and data?",
					answer:
						"You can delete your account and remove your data from the Account menu in the Ingomu app. Click the Delete Account option in the My Account section.",
					id: "headingEight",
					target: "#collapseEight",
					controls: "collapseEight",
					show: "",
				}
			],
		}
	},
	mounted() {
		if (this.$route.hash) {
			setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
		}
		this.$store.dispatch("getPricing");
		postscribe(
			"#home",
			`<script src="https://dktoyr513tjgs.cloudfront.net/v2/vendor/mega-menu/assets/js/custom.js"><\/script>`
		);
		postscribe(
			"#home",
			`<script src="https://dktoyr513tjgs.cloudfront.net/v2/js/theme.js?v=3"><\/script>`
		);
	},
	methods: {
		...mapActions(["newsletterConsumer"]),
		submit(scope) {
            var _this = this;
            this.$validator.validateAll(scope).then(function (res) {
                if (res) {
                    _this
                        .newsletterConsumer({
                            email: _this.newsletterForm.email
                        })
                        .then(() => {
                            _this.newsletterForm.email = "";
                            _this.$validator.reset();
                            _this.$fire({
                                title: "Success",
                                text: "Thank you for subscribing. Welcome to our community! Look for your first coaching tips shortly.",
                                type: "success",
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            _this.$fire({
                                title: "Error",
                                text: "Sorry, there seems to be a problem.",
                                type: "error",
                            });
                        });
                }
            });
        },
		closeModal(value) {
			if (value) {
				this.$fire({
					title: "Success",
					text: "Thank you! Our team has received your request. Keep an eye on your inbox; we'll be in touch shortly to schedule our call. Thank you for choosing Ingomu.",
					type: "success",
				});
			}
		},
		scrollTo(hashtag) {
			// setTimeout(() => { document.getElementById(hashtag.slice(1)).scrollIntoView() }, TIMEOUT)
			setTimeout(() => {
				const element = document.getElementById(hashtag.slice(1)); // Your target element
				const headerOffset = 150;
				const elementPosition = element.getBoundingClientRect().top;
				const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				});
			}, TIMEOUT)
		},
	},
	computed: {
		...mapGetters([
			"monthlyPrice",
			"semiPrice",
			"annualPrice"
		]),

	}
}
</script>
<style scoped>
.w997 {
	width: 997px;
}

.app-screen-preview-one {
	margin-top: 70px !important;
}

.block-style-six:nth-child(even):before {
	background: none;
}

.mb-50 {
	margin-bottom: 50px;
}

.mt-150 {
	margin-top: 150px;
}

.fs-20 {
	font-size: 20px !important;
}

.fs-45 {
	font-size: 45px;
}

.pb-0 {
	padding-bottom: 0;
}

.bg-lblue {
	background: #F7FAFF;
}

.bg-white {
	background: #fff;
}

.b-neg50 {
	bottom: -50px
}

.client-feedback-slider-two .bg-wrapper:before {
	bottom: 5px;
}

.fs-18 {
	font-size: 18px !important;
}

.fs-16 {
	font-size: 16px !important;
}

.pt-40 {
	padding-top: 40px;
}

.fs-30 {
	font-size: 30px;
}

.pb-15 {
	padding-bottom: 15px !important;
}

.pt-100 {
	padding-top: 100px !important
}

.pb-150 {
	padding-bottom: 150px
}

.p40 {
	padding: 40px !important;
}

.counter-box-one p {
	font-size: 15px !important;
}

.pt-150 {
	padding-top: 150px !important
}

.fs30 {
	font-size: 30px !important;
}

.counter-box-one.color-three,
.counter-info-classic .bubble-two,
.counter-info-classic .bubble-four,
.counter-info-classic .bubble-five,
.counter-info-classic .bubble-seven {
	background: #3b5998;
}

.counter-box-one.color-two,
.counter-info-classic .bubble-one,
.counter-info-classic .bubble-three,
.counter-info-classic .bubble-six {
	background: #46c346;
}

.mb40 {
	margin-bottom: 40px !important;
}

.title-style-four span img {
	bottom: -50px !important
}

.fw600 {
	font-weight: 600 !important;
}

.h22 {
	height: 22px;
}

.download-btn button {
	width: 190px;
	line-height: 60px;
	border-radius: 9px;
	font-weight: 500;
	font-size: 18px;
	color: #000;
	background: #FFEAA0;
	padding-left: 12px;
}

.download-btn.style-two button {
	background: #fff;
	border: 2px solid #000;
	line-height: 56px;
}

.download-btn button:after {
	content: url(../assets/v2/images/icon/89.svg);
	border: none;
	margin: 0 0 0 11px;
	vertical-align: 0;
	transition: all 0.2s ease-in-out;
}

.download-btn.show button:after {
	transform: rotate(180deg);
}

.download-btn .dropdown-menu {
	width: 100%;
	background: #FFF5D1;
	border-radius: 0px 0px 10px 10px;
	border: none;
	margin: -5px 0 0 0;
}

.download-btn.style-two .dropdown-menu {
	background: #fff;
	border: 2px solid #000;
}

.download-btn .dropdown-menu a {
	font-weight: 500;
	font-size: 15px;
	color: #000;
	padding: 8px 15px;
	transition: all 0.3s ease-in-out;
}

.download-btn .dropdown-menu a:hover,
.download-btn .dropdown-menu a:focus {
	background: rgba(255, 255, 255, 0.5);
}

.download-btn .dropdown-menu a span {
	padding-left: 12px;
}

.w100 {
	width: 100% !important;
}

.fs58 {
	font-size: 58px;
}

.fs12 {
	font-size: 12px !important;
}

.clientSliderSix .feedback-wrapper:before,
.clients-feedback-classic .feedback-wrapper:before {
	content: none !important;
}
</style>
<style>
@media (max-width: 768px) {
	.body-padding {
		padding: 0 20px !important;
	}
}

@media (max-width: 1370px) {
	.clients-feedback-classic {
		padding-left: 40px;
	}
}

.hero-banner-fourteen {
	padding-top: 100px !important;
}

.hero-banner-fourteen .hero-heading {
	font-size: 50px;
}

.screen-holder {
	display: flex;
	justify-content: center;
	align-items: center;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}

.rainbow {
	position: relative;
	z-index: 0;
	width: 500px;
	height: 500px;
	border-radius: 50%;
	overflow: hidden;
	padding: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: sans-serif;
	font-weight: bold;

	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background-color: #399953;
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#3b5998, #3b5998), linear-gradient(#46c346, #46c346);
		animation: rotate 4s linear infinite;
	}

	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 6px;
		top: 6px;
		width: calc(100% - 12px);
		height: calc(100% - 12px);
		background: white;
		border-radius: 50%;
	}
}

@media (max-width: 768px) {
	.rainbow {
		width: 400px;
		height: 400px;
	}

	.display-none {
		display: none;
	}
}


.hero-banner-fourteen .screen-holder .vr-image {
	right: auto;
}

.hero-banner-fourteen .screen-holder {
	top: 5%;
}

@media (max-width: 1370px) {
	.hero-banner-fourteen .screen-holder {
		margin-top: 0;
	}
}

.hero-banner-fourteen .screen-holder .vr-image {
	bottom: auto;
}

.fs-35 {
	font-size: 35px !important;
}

.hero-banner-fourteen .hero-heading {
	text-transform: none;
}

.fancy-short-banner-four .bg-wrapper {
	background: #3b5998;
	border-radius: 25px;
}

.mt-100 {
	margin-top: 100px !important
}

.tl-50 {
	transform: translateX(40%);
}

.fs-50 {
	font-size: 50px !important;
}

.fw-600 {
	font-weight: 600 !important;
}

.client-feedback-slider-seven .bg-image {
	max-height: 100%;
	max-width: 100%;
	padding: 0 20px;
}

.hero-banner-fourteen .price {
	font-size: 60px;
}

@media (max-width: 1199px) {
	.hero-banner-fourteen .price {
		font-size: 45px;
	}
}

.fancy-short-banner-four .form-wrapper form button:hover {
	background: #46c346 !important;
	color: #fff;
}

@media (max-width: 767px) {
	.sm-mt-0 {
		margin-top: 0 !important;
	}

	.sm-pt-0 {
		padding-top: 0 !important
	}
}

#actionemail::placeholder {
	color: white;
}
</style>