import * as fb from '@/api/firebase.js';
import { data } from 'jquery';
import { vuexfireMutations, firestoreAction } from 'vuexfire'

const state = {
    blogList: [],
    previewList: [],
    blogShortList: [],
    blogItem: [],
    catList: [],
    firstVisibleAll: "",
    lastVisibleAll: "",
    next_all: false,
    prev_all: false,
    limitAll: 7,
    firstVisibleCategory: "",
    lastVisibleCategory: "",
    next_category: false,
    prev_category: false,
    limitCategory: 7,
    firstVisibleTags: "",
    lastVisibleTags: "",
    next_tags: false,
    prev_tags: false,
    limitTags: 7,
}

const getters = {
    blogList: state => state.blogList,
    previewList: state => state.previewList,
    blogShortList: state => state.blogShortList,
    blogItem: state => state.blogItem,
    catList: state => state.catList,
    firstVisibleAll: state => state.firstVisibleAll,
    lastVisibleAll: state => state.lastVisibleAll,
    next_all: state => state.next_all,
    prev_all: state => state.prev_all,
    limitAll: state => state.limitAll,
    firstVisibleCategory: state => state.firstVisibleCategory,
    lastVisibleCategory: state => state.lastVisibleCategory,
    next_category: state => state.next_category,
    prev_category: state => state.prev_category,
    limitCategory: state => state.limitCategory,
    firstVisibleTags: state => state.firstVisibleCategory,
    lastVisibleTags: state => state.lastVisibleCategory,
    next_tags: state => state.next_tags,
    prev_tags: state => state.prev_tags,
    limitTags: state => state.limitTags
}

const mutations = {
    ...vuexfireMutations,
    SET_BLOG_ITEM(state, payload) {
        state.blogItem = payload.blogArray
    }
}

const actions = {
    resetBlogList(context, payload) {
        state.firstVisibleAll = "";
        state.lastVisibleAll = "";
        state.next_all = false;
        state.prev_all = false;
    },
    getBlogList(context, payload) {
        fb.blogCollection
        .where("online", "==", true)
        .orderBy("date", "desc")
        .get()
        .then((querySnapshot) => {
            let data = querySnapshot.docs.map(doc => doc.data());
            state.blogList = data;   
        })
    },
    getNextBlogList(context, payload) {
        fb.blogCollection
        .where("online", "==", true)
        .orderBy("date", "desc")
        .startAfter(state.lastVisibleAll)
        .limit(state.limitAll)
        .get()
        .then((querySnapshot) => {
            state.lastVisibleAll = querySnapshot.docs[querySnapshot.docs.length - 1]
            state.firstVisibleAll = querySnapshot.docs[0];
            let data = querySnapshot.docs.map(doc => doc.data());
            state.blogList = data;

            fb.blogCollection
            .where("online", "==", true)
            .orderBy("date", "desc")
            .startAfter(state.lastVisibleAll)
            .limit(state.limitAll)
            .get()
            .then(snap => {
                if (snap.size === 0) {
                    state.next_all = false
                    state.prev_all = true
                } else {
                    state.next_all = true
                    state.prev_all = true
                }
                if (payload.firstTime) {
                    state.prev_all = false
                }
            })          
        })
    },
    getPrevBlogList(context, payload) {
        fb.blogCollection
        .where("online", "==", true)
        .orderBy("date", "desc")
        .endBefore(state.firstVisibleAll)
        .limitToLast(state.limitAll)
        .get()
        .then((querySnapshot) => {
            if (!querySnapshot.empty) {
                state.lastVisibleAll = querySnapshot.docs[querySnapshot.docs.length - 1]
                state.firstVisibleAll = querySnapshot.docs[0];
                let data = querySnapshot.docs.map(doc => doc.data());
                state.blogList = data;

                fb.blogCollection
                .where("online", "==", true)
                .orderBy("date", "desc")
                .endBefore(state.firstVisibleAll)
                .limitToLast(state.limitAll)
                .get()
                .then(snap => {
                    if (snap.size === 0) {
                        state.next_all = true
                        state.prev_all = false
                    } else {
                        state.next_all = true
                        state.prev_all = true
                    }
                })   
            }
        })
    },
    resetBlogCategoryList(context, payload) {
        state.firstVisibleCategory = "";
        state.lastVisibleCategory = "";
        state.next_category = false;
        state.prev_category = false;
    },
    getBlogCategory(context, payload) {
        if (!payload.category.isEmpty) {
            fb.blogCollection
            .where("online", "==", true)
            .where("catslug", "==", payload.category)
            .orderBy("date", "desc")
            .get()
            .then((querySnapshot) => {
                let data = querySnapshot.docs.map(doc => doc.data());
                state.blogList = data;
            })
        }
    },
    getNextBlogCategory(context, payload) {
        if (!payload.category.isEmpty) {
            fb.blogCollection
            .where("online", "==", true)
            .where("catslug", "==", payload.category)
            .orderBy("date", "desc")
            .startAfter(state.lastVisibleCategory)
            .limit(state.limitCategory)
            .get()
            .then((querySnapshot) => {
                state.lastVisibleCategory = querySnapshot.docs[querySnapshot.docs.length - 1]
                state.firstVisibleCategory = querySnapshot.docs[0];
                let data = querySnapshot.docs.map(doc => doc.data());
                state.blogList = data;
                fb.blogCollection
                .where("online", "==", true)
                .where("catslug", "==", payload.category)
                .orderBy("date", "desc")
                .startAfter(state.lastVisibleCategory)
                .limit(state.limitCategory)
                .get()
                .then(snap => {
                    if (snap.size === 0) {
                        state.next_category = false
                        state.prev_category = true
                    } else {
                        state.next_category = true
                        state.prev_category = true
                    }
                    if (payload.firstTime) {
                        state.prev_category = false
                    }
                })          
            })
        }
    },
    getPrevBlogCategory(context, payload) {
        if (!payload.category.isEmpty) {
            fb.blogCollection
            .where("online", "==", true)
            .where("catslug", "==", payload.category)
            .orderBy("date", "desc")
            .endBefore(state.firstVisibleCategory)
            .limitToLast(state.limitCategory)
            .get()
            .then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    state.lastVisibleCategory = querySnapshot.docs[querySnapshot.docs.length - 1]
                    state.firstVisibleCategory = querySnapshot.docs[0];
                    let data = querySnapshot.docs.map(doc => doc.data());
                    state.blogList = data;

                    fb.blogCollection
                    .where("online", "==", true)
                    .where("catslug", "==", payload.category)
                    .orderBy("date", "desc")
                    .endBefore(state.firstVisibleCategory)
                    .limitToLast(state.limitCategory)
                    .get()
                    .then(snap => {
                        if (snap.size === 0) {
                            state.next_category = true
                            state.prev_category = false
                        } else {
                            state.next_category = true
                            state.prev_category = true
                        }
                    })   
                }
            })
        }
    },
    resetBlogtagList(context, payload) {
        state.firstVisibleTags = "";
        state.lastVisibleTags = "";
        state.next_tags = false;
        state.prev_tags = false;
    },
    getNextBlogTags(context, payload) {
        if (!payload.tags.isEmpty) {
            fb.blogCollection
            .where("online", "==", true)
            .where("tags", "array-contains", payload.tags)
            .orderBy("date", "desc")
            .startAfter(state.lastVisibleTags)
            .limit(state.limitTags)
            .get()
            .then((querySnapshot) => {
                state.lastVisibleTags = querySnapshot.docs[querySnapshot.docs.length - 1]
                state.firstVisibleTags = querySnapshot.docs[0];
                let data = querySnapshot.docs.map(doc => doc.data());
                state.blogList = data;

                fb.blogCollection
                .where("online", "==", true)
                .where("tags", "array-contains", payload.tags)
                .orderBy("date", "desc")
                .startAfter(state.lastVisibleTags)
                .limit(state.limitTags)
                .get()
                .then(snap => {
                    if (snap.size === 0) {
                        state.next_tags = false
                        state.prev_tags = true
                    } else {
                        state.next_tags = true
                        state.prev_tags = true
                    }
                    if (payload.firstTime) {
                        state.prev_tags = false
                    }
                })          
            })
        }
    },
    getPrevBlogTags(context, payload) {
        if (!payload.tags.isEmpty) {
            fb.blogCollection
            .where("online", "==", true)
            .where("tags", "array-contains", payload.tags)
            .orderBy("date", "desc")
            .endBefore(state.firstVisibleTags)
            .limitToLast(state.limitTags)
            .get()
            .then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    state.lastVisibleTags = querySnapshot.docs[querySnapshot.docs.length - 1]
                    state.firstVisibleTags = querySnapshot.docs[0];
                    let data = querySnapshot.docs.map(doc => doc.data());
                    state.blogList = data;

                    fb.blogCollection
                    .where("online", "==", true)
                    .where("tags", "array-contains", payload.tags)
                    .orderBy("date", "desc")
                    .endBefore(state.firstVisibleTags)
                    .limitToLast(state.limitTags)
                    .get()
                    .then(snap => {
                        if (snap.size === 0) {
                            state.next_tags = true
                            state.prev_tags = false
                        } else {
                            state.next_tags = true
                            state.prev_tags = true
                        }
                    })   
                }
            })
        }
    },
    getLastNineBlogList: firestoreAction((context, payload) => {
        return context.bindFirestoreRef('blogShortList',
        fb.blogCollection
            .where("online", "==", true)
            .orderBy("date", "desc")
            .limit(9)
        )
    }),
    getLastFiveBlogList: firestoreAction((context, payload) => {
        return context.bindFirestoreRef('blogShortList',
        fb.blogCollection
            .where("online", "==", true)
            .orderBy("date", "desc")
            .limit(5)
        )
    }),
    getBlogItem: firestoreAction((context, payload) => { 
        return context.bindFirestoreRef('blogItem',
        fb.blogCollection
        .where("slug", "==", payload.slug)
        .where("online", "==", true)
        )
    }),
    getBlogPreview: firestoreAction((context, payload) => { 
        return context.bindFirestoreRef('previewList',
        fb.blogCollection
        .where("slug", "==", payload.slug)
        .where("online", "==", false)
        )
    }),
    getCategoryList: firestoreAction((context, payload) => {
        return context.bindFirestoreRef('catList',
        fb.blogCatCollection
            .where("online", "==", true)
            .orderBy("order")
        )
    }),
}

export default {
    state,
    getters,
    mutations,
    actions
}
