<template>
    <div id="companysignup">
        <div class="main-page-wrapper p0">
            <!-- ===================================================
				Loading Transition
			==================================================== -->
            <section>
                <div id="preloader">
                    <div id="ctn-preloader" class="ctn-preloader">
                        <div class="animation-preloader">
                            <div class="text-center">
                                <img class="spinner" src="../../../assets/image/ingomu-preloader.gif" alt="" />
                            </div>
                            <!-- <div class="spinner"></div> -->
                            <div class="txt-loading">
                                <span data-text-preloader="i" class="letters-loading">
                                    i
                                </span>
                                <span data-text-preloader="n" class="letters-loading">
                                    n
                                </span>
                                <span data-text-preloader="g" class="letters-loading-green">
                                    g
                                </span>
                                <span data-text-preloader="o" class="letters-loading-green">
                                    o
                                </span>
                                <span data-text-preloader="m" class="letters-loading">
                                    m
                                </span>
                                <span data-text-preloader="u" class="letters-loading">
                                    u
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <!-- 
			=============================================
				Theme Main Menu
			============================================== 
			-->
            <SiteHeader :isPaid="isPaid"></SiteHeader>


            <!-- 
			=============================================
				Fancy Text block Eleven
			============================================== 
			-->
            <div class="fancy-text-block-eleven mt-200 md-mt-100" v-if="videourl != '' && videothumbnail != ''">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 order-lg-last">
                            <div class=" pt-0 md-pb-50">
                                <div class="title-style-two mb-35 md-mb-20 text-center">
                                    <h2><span>{{ name }} <img src="../../../assets/v2/images/shape/line-shape-5.svg"
                                                alt=""></span></h2>
                                </div> <!-- /.title-style-two -->
                            </div>
                        </div>
                        <div class="col-lg-6 order-lg-first">
                            <div class="video-box">
                                <img :src="videothumbnail" alt="">
                                <a data-fancybox="" :href="videourl"
                                    class="fancybox video-button d-flex align-items-center justify-content-center bg-ingomugreen"><img
                                        src="../../../assets/v2/images/icon/170.svg" alt=""></a>
                            </div> <!-- /.video-box -->
                        </div>
                    </div>
                </div>
            </div> <!-- /.fancy-text-block-eleven -->


            <!-- 
			=============================================
				Fancy Feature Twenty Five
			============================================== 
			-->
            <div class="fancy-feature-twentyFive lg-container mt-100 margin-0-100 md-mt-100 md-m-0" id="about"
                v-if="description != ' ' && videourl.length !== 0">
                <div class="container">
                    <div class="block-style-twentyFive">
                        <div class="row align-items-center text-center">
                            <!-- <div class="col-xl-7 col-lg-6 col-md-10 m-auto" data-aos="fade-right" data-aos-duration="1200">
								<div class="screen-container">
									<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/hand_phone_logo_v1.png"
										alt="">
								</div> 
							</div> -->
                            <div class="col-xl-12 col-lg-12" data-aos="fade-left" data-aos-duration="1200">
                                <div class="text-wrapper pl-xl-5 text-left">
                                    <p class="fs-18" v-html="nl2br(description)"></p>
                                </div> <!-- /.text-wrapper -->
                            </div>
                        </div>
                    </div> <!-- /.block-style-twentyFive -->

                </div>
            </div> <!-- /.fancy-feature-twentyFive -->
            <div class="fancy-feature-twentyFive lg-container mt-200 md-mt-100" id="about"
                v-if="description != ' ' && videourl.length === 0">
                <div class="container">
                    <div class="block-style-twentyFive">
                        <div class="row align-items-center text-center">
                            <!-- <div class="col-xl-7 col-lg-6 col-md-10 m-auto" data-aos="fade-right" data-aos-duration="1200">
								<div class="screen-container">
									<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/hand_phone_logo_v1.png"
										alt="">
								</div> 
							</div> -->
                            <div class="col-xl-12 col-lg-12" data-aos="fade-left" data-aos-duration="1200">
                                <div class="text-wrapper pl-xl-5 text-left">
                                    <p class="fs-18" v-html="nl2br(description)"></p>
                                </div> <!-- /.text-wrapper -->
                            </div>
                        </div>
                    </div> <!-- /.block-style-twentyFive -->

                </div>
            </div> <!-- /.fancy-feature-twentyFive -->


            <div id="signup" v-if="show == 'signup'">
                <!--
			=====================================================
				Pricing Section Five
			=====================================================
			-->
                <div class="pricing-section-five mt-100 md-mt-90 sm-mt-80 md-pt-10" id="pricing" v-if="isPaid">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-5 order-lg-last">
                                <div class="title-style-six pl-5 md-p0">
                                    <h6 class="fs-15">SUBSCRIPTIONS</h6>
                                    <h2 class="fs-52">Join Today</h2>
                                    <!-- <p class="text-xs">Sign up for Ingomu</p> -->
                                </div> <!-- /.title-style-six -->
                            </div>
                            <div class="col-lg-7">
                                <div class="pricing-table-area-seven md-mt-60">
                                    <div class="row align-items-center">
                                        <div class="col-md-6">
                                            <div class="pr-table-wrapper active">
                                                <img src="images/icon/85.svg" alt="" class="icon">
                                                <div class="pack-name">Semi-Annual</div>
                                                <ul class="pr-feature">
                                                    <li>Limited time offer</li>
                                                    <li>Access all coaches</li>
                                                    <li>Unlimited live group sessions</li>
                                                    <li>Access on-demand sessions</li>
                                                    <li>Cancel any time</li>
                                                </ul>
                                                <div class="price">${{ priceForm.semiPrice }}</div>
                                                <div class="trial-text">Save with an semi-annual plan</div>
                                                <button ref="semiannual" class="trial-button text-white"
                                                    @click="setPrice('semi')"
                                                    :class="paymentForm.isSemi ? 'btn-ingomugreen' : 'btn-ingomublue'">
                                                    Get Started
                                                </button>
                                            </div> <!-- /.pr-table-wrapper -->
                                        </div>
                                        <div class="col-md-6">
                                            <div class="pr-table-wrapper sm-mt-80">
                                                <img src="../../../assets/v2/images/shape/159.svg" alt=""
                                                    class="popular-badge">
                                                <div class="pack-name">Annual</div>
                                                <ul class="pr-feature">
                                                    <li>Access all coaches</li>
                                                    <li>Unlimited live group sessions</li>
                                                    <li>Access on-demand sessions</li>
                                                    <li>Cancel any time</li>
                                                </ul>
                                                <div class="price">${{ priceForm.annualPrice }}</div>
                                                <div class="trial-text">Save with an annual plan</div>
                                                <button ref="annual" class="trial-button text-white"
                                                    @click="setPrice('annual')"
                                                    :class="paymentForm.isAnnual ? 'btn-ingomugreen' : 'btn-ingomublue'">
                                                    Get Started
                                                </button>
                                            </div> <!-- /.pr-table-wrapper -->
                                        </div>
                                    </div>
                                </div> <!-- /.pricing-table-area-five -->
                            </div>
                        </div>
                    </div>
                </div> <!-- /.pricing-section-five -->
                <div :class="isPaid ? 'mt-80' : ''" class="row justify-content-center mt-100 md-mt-100" id="signup">
                    <div class="col-lg-6 col-md-8 col-sm-12">
                        <div id="credsection"></div>
                        <div class="section-title section-title--l5 pb-6" v-if="isPaid">
                            <div class="text-center">
                                <div class="table-top">
                                    <h2 data-aos="fade-up">
                                        Create your account</h2>
                                </div>
                            </div>
                        </div>
                        <div class="section-title section-title--l5 pb-6" v-else>
                            <div class="text-center">
                                <div class="table-top">
                                    <h2 data-aos="fade-up">
                                        Sign up for Ingomu</h2>
                                </div>
                            </div>
                        </div>
                        <div class="form-box form-box--sign-up mr-0" data-aos="fade-up" data-aos-duration="500"
                            data-aos-delay="500" data-aos-once="true" v-if="!isPaid">
                            <div class="contact-form" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300"
                                data-aos-once="true">
                                <form class="form" id="kt_signup_form" novalidate="novalidate">
                                    <div class="form-floating mb-20">
                                        <input :class="{ error: v$.signupForm.signupaccesscode.$errors.length }"
                                            class="form-control" type="text" placeholder="Access Code"
                                            name="signupaccesscode" ref="signupaccesscode" id="signupaccesscode"
                                            v-model="signupForm.signupaccesscode" />
                                        <label for="signupaccesscode">Access Code</label>
                                    </div>
                                    <div class="form-floating mb-20">
                                        <input :class="{ error: v$.signupForm.signupfirstname.$errors.length }"
                                            class="form-control" type="text" placeholder="First Name" name="signupfirstname"
                                            ref="signupfirstname" v-model="signupForm.signupfirstname" />
                                        <label for="signupfirstname">First Name</label>
                                    </div>
                                    <div class="form-floating mb-20">
                                        <input :class="{ error: v$.signupForm.signupemail.$errors.length }"
                                            class="form-control" type="email" placeholder="Email" name="signupemail"
                                            ref="signupemail" v-model="signupForm.signupemail" />
                                        <label for="signupemail">Email</label>
                                    </div>
                                    <div class="form-floating mb-20">
                                        <input :class="{ error: v$.signupForm.signuppassword.$errors.length }"
                                            class="form-control" type="password" placeholder="Password"
                                            name="signuppassword" ref="signuppassword"
                                            v-model="signupForm.signuppassword" />
                                        <label for="signuppassword">Password</label>
                                    </div>
                                    <div class="form-floating mb-20">
                                        <input :class="{ error: v$.signupForm.signupverify.$errors.length }"
                                            class="form-control" type="password" placeholder="Verify Password"
                                            name="signupverify" ref="signupverify" v-model="signupForm.signupverify" />
                                        <label for="signupverify">Verify Password</label>
                                    </div>
                                    <div class="form-check d-flex mt-6 mb-3 pl0 pr0">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            By continuing I certify that I am 18 years of age or older, and I
                                            agree to the
                                            Ingomu <router-link to="/terms" target="_blank">terms & conditions
                                            </router-link> and Ingomu <router-link to="/terms#privacy"
                                                target="_blank">privacy
                                                policy</router-link>. If you already have an account, your existing password
                                            will be used to sign in.
                                        </label>
                                    </div>
                                    <vue-honeypot ref="honeypot" />
                                    <button ref="kt_save_signup" name="kt_save_signup"
                                        class="btn btn-ingomublue shadow--ingomublue-4 btn--lg-2 rounded-55 text-white mt-3"
                                        @click.prevent="submitSignupForm">
                                        Sign Up
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div class="form-box form-box--sign-up mr-0" data-aos="fade-up" data-aos-duration="500"
                            data-aos-delay="500" data-aos-once="true" v-else>
                            <div class="contact-form" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300"
                                data-aos-once="true">
                                <form class="form" id="kt_payment_form" novalidate="novalidate">
                                    <div class="form-floating mb-20">
                                        <input :class="{ error: v$.paymentForm.signupfirstname.$errors.length }"
                                            class="form-control" type="text" placeholder="First Name"
                                            name="paymentfirstname" ref="paymentfirstname"
                                            v-model="paymentForm.signupfirstname" />
                                        <label for="paymentfirstname">First Name</label>
                                    </div>
                                    <div class="form-floating mb-20">
                                        <input :class="{ error: v$.paymentForm.signupemail.$errors.length }"
                                            class="form-control" type="email" placeholder="Email" name="paymentemail"
                                            ref="paymentemail" v-model="paymentForm.signupemail" />
                                        <label for="paymentemail">Email</label>
                                    </div>
                                    <div class="form-floating mb-20">
                                        <input :class="{ error: v$.paymentForm.signuppassword.$errors.length }"
                                            class="form-control" type="password" placeholder="Password"
                                            name="paymentpassword" ref="paymentpassword"
                                            v-model="paymentForm.signuppassword" />
                                        <label for="paymentpassword">Password</label>
                                    </div>
                                    <div class="form-floating mb-20">
                                        <input :class="{ error: v$.paymentForm.signupverify.$errors.length }"
                                            class="form-control" type="password" placeholder="Verify Password"
                                            name="paymentverify" ref="paymentverify" v-model="paymentForm.signupverify" />
                                        <label for="paymentverify">Verify Password</label>
                                    </div>
                                    <div class="form-check d-flex mt-6 mb-3">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            By continuing I certify that I am 18 years of age or older, and I
                                            agree to the
                                            Ingomu <router-link to="/terms" target="_blank">terms & conditions
                                            </router-link> and Ingomu <router-link to="/terms#privacy"
                                                target="_blank">privacy
                                                policy</router-link>. If you already have an account, your existing password
                                            will be used to sign in.
                                        </label>
                                    </div>
                                    <div class="mt-6" id="paypal-button-container"></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--
			=====================================================
				Faq Classic
			=====================================================
			-->
            <div class="faq-classic pt-100 md-pt-100" id="faq">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="title-style-one">
                                <h6 class="font-rubik">Find your answers</h6>
                                <h2>Frequently Asked Questions</h2>
                            </div> <!-- /.title-style-one -->
                        </div>
                        <div class="col-lg-6">
                            <!-- ================== FAQ Panel ================ -->
                            <div class="md-mt-60" id="accordionExample">
                                <div v-for="(item, index) in faqGeneral" :key="index" class="card">
                                    <div class="card-header" :id="item.id">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" type="button" data-bs-toggle="collapse"
                                                :data-bs-target="item.target" aria-expanded="false"
                                                :aria-controls="item.controls">
                                                {{ item.question }}
                                            </button>
                                        </h5>
                                    </div>
                                    <div :id="item.controls" class="collapse" :class="item.show" :aria-labelledby="item.id"
                                        data-bs-parent="#accordionExample">
                                        <div class="card-body" v-html="nl2br(item.answer)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- /.faq-classic -->

            <!--
			=====================================================
				Footer Style Seven
			=====================================================
			-->
            <SiteFooter></SiteFooter>


        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import postscribe from "postscribe";
import SiteHeader from "@/components/SignupHeader";
import SiteFooter from "@/components/SiteFooter";
import nl2brMixin from "@/mixins/nl2br";
import Swal from "sweetalert2";
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, sameAs } from '@vuelidate/validators'
import * as fb from '@/api/firebase.js';
import videojs from "video.js";
import "video.js/dist/video-js.css";
import VueHoneypot from 'vue-honeypot'

const TIMEOUT = 1000;

export default {
    setup() {
        return { v$: useVuelidate() }
    },
    name: "companysignup",
    metaInfo() {
        return {
            title: "Register with " + this.name + " on Ingomu",
        }
    },
    components: {
        SiteHeader,
        SiteFooter,
        VueHoneypot
    },
    data() {
        return {
            videourl: "",
            videothumbnail: "",
            signupForm: {
                signupaccesscode: '',
                signupemail: '',
                signuppassword: '',
                signupverify: '',
                signupCorpId: '',
                signupClientIp: '',
                signupfirstname: ''
            },
            paymentForm: {
                signupemail: '',
                signuppassword: '',
                signupverify: '',
                signupCorpId: '',
                signupClientIp: '',
                signupfirstname: '',
                orderID: '',
                payerID: '',
                price: 0,
                isSemi: false,
                isAnnual: false,
            },
            loginForm: {
                accesscode: '',
                email: '',
                password: '',
                loginCorpId: '',
                loginClientIp: ''
            },
            forgotForm: {
                email: ''
            },
            priceForm: {
                annualPrice: 0,
                semiPrice: 0,
                bestValue: "annual"
            },
            corpImage: "https://dktoyr513tjgs.cloudfront.net/image/ingomu_logo-2.png",
            description: "",
            faqGeneral: [],
            show: "signup",
            video: "",
            name: "",
            isPaid: false,
        }
    },
    mounted() {
        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
        }
        this.$store.dispatch("getCorpDetails", { slug: this.$route.params.slug })
        this.$store.dispatch("getPricing")
        postscribe(
            "#companysignup",
            `<script src="https://dktoyr513tjgs.cloudfront.net/v2/vendor/mega-menu/assets/js/custom.js"><\/script>`
        );
        postscribe(
            "#companysignup",
            `<script src="https://dktoyr513tjgs.cloudfront.net/v2/js/theme.js?v=3"><\/script>`
        );
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(response => {
                this.signupForm.signupClientIp = response.ip;
                this.paymentForm.signupClientIp = response.ip;
                this.loginForm.loginClientIp = response.ip;
            }).catch(error => {
                console.log(error)
            });
        this.$mixpanel.track('referral', {
            'action': 'viewed',
            'date': new Date().toString(),
            'slug': this.$route.params.slug
        });
    },
    mixins: [nl2brMixin],
    validations() {
        return {
            signupForm: {
                signupaccesscode: { required },
                signupemail: { required, email },
                signuppassword: { required, minLength: minLength(6) },
                signupverify: { required, sameAsPassword: sameAs(this.signupForm.signuppassword) },
                signupfirstname: { required }
            },
            paymentForm: {
                signupemail: { required, email },
                signuppassword: { required, minLength: minLength(6) },
                signupverify: { required, sameAsPassword: sameAs(this.paymentForm.signuppassword) },
                signupfirstname: { required }
            },
            loginForm: {
                accesscode: { required },
                email: { required, email },
                password: { required, minLength: minLength(6) },
            },
            forgotForm: {
                email: { required, email }
            }
        }
    },
    methods: {
        setPrice(period) {
            if (period == "semi") {
                this.paymentForm.isSemi = true
                this.paymentForm.isAnnual = false
                this.$refs.semiannual.innerText = "Selected"
                this.$refs.annual.innerText = "Get Started"
                this.paymentForm.price = this.priceForm.semiPrice
            } else if (period == "annual") {
                this.paymentForm.isSemi = false
                this.paymentForm.isAnnual = true
                this.$refs.semiannual.innerText = "Get Started"
                this.$refs.annual.innerText = "Selected"
                this.paymentForm.price = this.priceForm.annualPrice
            }
            setTimeout(function () {
                const yOffset = -100;
                const element = document.getElementById("credsection");
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({ top: y, behavior: 'smooth' });
            }, 100);
        },
        async submitSignupForm() {
            try {
                this.$refs.honeypot.validate()
                let self = this;
            const isFormCorrect = await this.v$.signupForm.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (!isFormCorrect) {
                Swal.fire({
                    title: "",
                    text: "Please, complete all fields.",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
                return
            }
            // actually submit form
            const submitButton = this.$refs["kt_save_signup"];
            submitButton.classList.add("disabled");
            this.$store.dispatch("signupCorpUser", this.signupForm).then(
                function (value) {
                    self.$router.replace({ name: 'companythankyou', params: { slug: self.$route.params.slug, codegroupid: value } })
                },
                function (error) {
                    submitButton.classList.remove(
                        "disabled"
                    );
                    Swal.fire({
                        title: "",
                        text: error,
                        icon: "error",
                        confirmButtonClass: "btn btn-secondary",
                        heightAuto: true,
                    });
                });

            } catch (error) {
                // error handling
                console.log("faild validation, alert user")
            }
        },
        async resetForm() {
            let self = this;
            const isFormCorrect = await this.v$.forgotForm.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (!isFormCorrect) {
                Swal.fire({
                    title: "",
                    text: "Please, complete all fields.",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
                return
            }
            const submitButton = this.$refs["kt_forgot_send"];
            submitButton.classList.add("disabled");
            this.$store.dispatch("forgotPassword", this.forgotForm).then(
                function (value) {
                    submitButton.classList.remove(
                        "disabled"
                    );
                    Swal.fire({
                        title: "",
                        text: "A reset password email will be sent if that email exists in our system.",
                        icon: "success",
                        confirmButtonClass: "btn btn-secondary",
                        heightAuto: true,
                    });
                },
                function (error) {
                    submitButton.classList.remove(
                        "disabled"
                    );
                    Swal.fire({
                        title: "",
                        text: error,
                        icon: "error",
                        confirmButtonClass: "btn btn-secondary",
                        heightAuto: true,
                    });
                })
        },
        createOrder: async function (data, actions) {
            if (this.paymentForm.isSemi == true) {
                var setPrice = this.corpDetails[0].semi
            } else {
                var setPrice = this.corpDetails[0].price
            }
            return actions.order.create({
                purchase_units: [
                    {
                        amount: {
                            value: setPrice,
                        },
                    },
                ],
            });
        },
        onApprove: function (data, actions) {
            this.paymentForm.orderID = data.orderID
            this.paymentForm.payerID = data.payerID
            let self = this;

            return actions.order.capture().then(function () {
                self.$store.dispatch("signupPaymentUser", self.paymentForm).then(
                    function (value) {
                        self.$mixpanel.track('referral_purchase', {
                            'action': 'purchased',
                            'date': new Date().toString(),
                            'slug': self.$route.params.slug,
                            'price': self.paymentForm.price,
                            'isAnnual': self.paymentForm.isAnnual,
                            'isSemi': self.paymentForm.isSemi
                        });
                        if (self.paymentForm.isSemi == true) {
                            self.$router.replace({ name: 'companythankyoupaid', params: { slug: self.$route.params.slug, term: "semi" } })
                        } else {
                            self.$router.replace({ name: 'companythankyoupaid', params: { slug: self.$route.params.slug, term: "annual" } })
                        }
                    },
                    function (error) {
                        Swal.fire({
                            title: "",
                            text: error,
                            icon: "error",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        });
                    });
            });
        },
        scrollTo(hashtag) {
            setTimeout(() => {
                const element = document.getElementById(hashtag.slice(1)); // Your target element
                const headerOffset = 150;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }, TIMEOUT)
        }
    },
    computed: {
        ...mapGetters(["corpDetails", "monthlyPrice"]),
        faqs() {
            return this.faqGeneral
        }
    },
    watch: {
        corpDetails(newValue) {
            if (newValue.length <= 0) {
                this.$router.push({ path: '/404' })
            } else {
                if (newValue[0].corpimage != "" && newValue[0].corpimage != null) {
                    this.corpImage = newValue[0].corpimage;
                }
                this.name = newValue[0].name
                this.description = newValue[0].description
                this.isPaid = newValue[0].isPaid
                setTimeout(() => {
                    const info = { page_path: location.pathname, page_title: newValue[0].name, page_location: location.href }
                    this.$gtag.pageview(info)
                }, 1000)
                // this.paymentForm.price = newValue[0].price
                this.signupForm.signupCorpId = newValue[0].corporate_id
                this.paymentForm.signupCorpId = newValue[0].corporate_id
                this.loginForm.loginCorpId = newValue[0].corporate_id
                this.priceForm.annualPrice = newValue[0].price
                this.priceForm.semiPrice = newValue[0].semi
                this.priceForm.bestValue = newValue[0].bestvalue
                if (newValue[0].video != "" && newValue[0].video != null && newValue[0].videothumbnail != "" && newValue[0].videothumbnail != null) {
                    this.videourl = newValue[0].video
                    this.videothumbnail = newValue[0].videothumbnail
                }
                if (newValue[0].faq && newValue[0].faq.length > 0) {
                    var faqArray = []
                    newValue[0].faq.forEach((doc, i) => {
                        var showHide = ""
                        if (i == 0) { showHide = "show" }
                        faqArray.push({
                            question: doc.question,
                            answer: doc.answer,
                            id: "heading" + i.toString(),
                            target: "#collapse" + i.toString(),
                            controls: "collapse" + i.toString(),
                            show: showHide,
                        })
                    })
                    this.faqGeneral = faqArray
                }
                if (newValue[0].isPaid) {
                    this.$loadScript({ "client-id": this.$clientId }).then((paypal) => {
                        let self = this;
                        paypal
                            .Buttons({
                                onClick: async function (data, actions) {
                                    const isFormCorrect = await self.v$.paymentForm.$validate()
                                    if (!isFormCorrect) {
                                        Swal.fire({
                                            title: "",
                                            text: "Please, complete all fields.",
                                            icon: "error",
                                            confirmButtonClass: "btn btn-secondary",
                                            heightAuto: true,
                                        });
                                        return false
                                    } else {
                                        return true
                                        // const doesExist = await fb.auth.fetchSignInMethodsForEmail(self.paymentForm.signupemail.trim().toLowerCase())
                                        // if (doesExist.length) {
                                        //     Swal.fire({
                                        //         title: "",
                                        //         text: "That email is already associated with an account.",
                                        //         icon: "error",
                                        //         confirmButtonClass: "btn btn-secondary",
                                        //         heightAuto: true,
                                        //     });
                                        //     return false
                                        // } else {
                                        //     return true
                                        // }
                                    }
                                },
                                createOrder: this.createOrder,
                                onApprove: this.onApprove,
                            })
                            .render("#paypal-button-container");
                    });
                }
            }
        }
    }

}
</script>
<style scoped>
.fs-18 {
    font-size: 18px !important;
}

.margin-0-100 {
    margin: 0 100px;
}

.mt-100 {
    margin-top: 100px;
}

.fs-15 {
    font-size: 15px !important
}

.fs-52 {
    font-size: 52px !important
}

.pricing-section-five:after {
    background: rgba(59, 89, 152, 0.15);
}

.pl0 {
    padding-left: 0 !important
}

.pr0 {
    padding-right: 0 !important
}

.popular-badge {
    top: -90px;
    left: -77px;
    position: absolute;
    z-index: 1;
    animation: jumpTwo 10s infinite linear;
}

@media (max-width: 1370px) {
    .md-width-100 {
        max-width: 100%;
        flex: 100%;
    }

    .md-m-0 {
        margin: 0 !important;
    }
}</style>