<template>
			<vue-cookie-accept-decline
            :showPostponeButton="true"
			@clicked-accept="cookieClickedAccept"
  @clicked-decline="cookieClickedDecline"
  @clicked-postpone="cookieClickedPostpone"
  @status="cookieStatus"
			elementId="myPanel1"
  position="bottom"
  ref="myPanel1"
  transitionName="slideFromBottom"
  type="bar">

  <template #message>
	We utilize various technologies, including cookies, on our website to process your data essential for website functionality. Tracking data is used for personalized website settings and anonymous statistics. By selecting "Reject," you enable only necessary cookies for site operation, such as logging in or form filling. For additional details, including your right to withdraw consent, refer to our <a href="https://ingomu.com/terms" target="_blank">privacy policy</a>.
  </template>

  <template #declineContent>
	Reject
  </template>

  <template #acceptContent>
	Agree
  </template>

</vue-cookie-accept-decline>

</template>
<script>
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

export default {
    name: 'cookies',
    components: {
		VueCookieAcceptDecline
	},
    methods: {
        cookieClickedPostpone(newValue) {
            this.$gtag.optIn()
        },
        cookieClickedAccept(newValue) {
            this.$gtag.optIn()
        },
        cookieClickedDecline(newValue) {
            this.$gtag.optOut()
        },
        cookieStatus(newValue) {
            if (newValue == "decline") {
                this.$gtag.optOut()
            } else {
                this.$gtag.optIn()
            }
        }
    }
}
</script>